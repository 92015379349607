<template>
  <div>
    <div class="wrapper">
      <div v-if="error" class="error" v-html="error"></div>
      <div v-else-if="message" class="message" v-html="message"></div>

      <form @submit.prevent="login" class="form">
        <h1>Login</h1>

        <div class="form-group">
          <label for="username">Email Address</label>
          <input type="text" id="username" v-model="username" required>
        </div>
        <div class="form-group">
          <label for="password">Password</label>
          <input type="password" id="password" v-model="password" required>
          <p class="help-text">
            <router-link to="/forgot-password">Forgot your password?</router-link>
          </p>
        </div>
        <div class="form-footer">
          <button type="submit" class="btn">Login</button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
export default {
  name: 'LoginPage',
  computed: {
    error() {
      return this.$store.getters.getError
    },
    message() {
      return this.$store.getters.getLoginMessage
    }
  },
  mounted() {
    this.$store.commit('clearError')
    this.$store.dispatch('checkAuth').then((valid) => {
      if (valid) {
        // already logged in
        if (this.$route.query.redirect) {
          this.$router.push(this.$route.query.redirect)
        } else {
          this.$router.push('/')
        }
      }
    })
  },
  data() {
    return {
      username: '',
      password: ''
    }
  },
  methods: {
    login() {
      this.$store.dispatch('login', {
        username: this.username,
        password: this.password
      }).then((valid) => {
        if (valid) {
          if (this.$route.query.redirect) {
            this.$router.push(this.$route.query.redirect)
          } else if (this.$store.getters.getUser.meta.default_password_nag) {
            this.$store.commit('setLoginMessage', 'You are using a temporary password. Please change your password now.');
            this.$router.push('/change-password')
          } else {
            this.$router.push('/')
          }
        }
      })
    }
  },
  metaInfo() {
    return {
      title: 'Member Login'
    }
  }
}
</script>
