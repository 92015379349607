<template>
  <figure class="inline-video">
    <img :src="poster.url" @click="playVideo" v-if="!playing" />
    <youtube ref="video" :video-id="videoID(video)" :player-vars="{start: 0, autoplay: 1, rel: 0}" v-if="playing" player-width="1920" player-height="1080" />
    <inline-svg class="play-button" :src="require('@/assets/icon-play.svg')" v-if="!playing" />
  </figure>
</template>

<script>

export default {
  name: 'InlineVideo',
  props: {
    video: {
      type: String,
      required: true
    },
    poster: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      playing: false,
      player: null
    }
  },
  methods: {
    videoID(url) {
      return url.split('v=')[1]
    },
    playVideo() {
      this.playing = true
    }
  }
}
</script>
