<template>
  <div>
    <div class="container">
      <div v-if="error" class="error" v-html="error"></div>
      <div v-else-if="message" class="message" v-html="message"></div>

      <form @submit.prevent="resetPassword" class="form">
        <h1>Reset Password</h1>

        <div class="form-group">
          <label for="password">Password</label>
          <input type="password" id="password" v-model="password" @input="updateScore" required>
          <p class="help-text">Password must be at least 8 characters long and include uppercase letters, lowercase letters, numbers, and special characters.</p>
        </div>

        <div class="form-group">
          <label for="password_confirm">Confirm Password</label>
          <input type="password" id="password_confirm" v-model="password_confirm" required>
        </div>

        <div class="form-footer">
          <button type="submit" class="btn">Reset Password</button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>

export default {
  name: 'ResetPassword',
  computed: {
    error() {
      return this.$store.getters.getError
    },
    message() {
      return this.$store.getters.getLoginMessage
    }
  },
  mounted() {
    this.$store.commit('logout')
    this.$store.dispatch('verifyResetCode', {
      email: this.$route.query.email,
      code: this.$route.query.code
    }).then((valid) => {
      if (!valid) {
        this.$store.commit('setLoginMessage', 'Invalid reset code')
        this.$router.push('/login')
      }
    })
  },
  data() {
    return {
      password: '',
      score: 0
    }
  },
  methods: {
    updateScore(event) {
      const password = event.target.value
      let score = 0

      if (password.length > 8) {
        score += 1 // require at least 8 characters
      }
      if (password.match(/[a-z]/)) {
        score += 1 // require lowercase letters
      }
      if (password.match(/[A-Z]/)) {
        score += 1 // require uppercase letters
      }
      if (password.match(/[0-9]/)) {
        score += 1 // require numbers
      }
      if (password.match(/[^a-zA-Z0-9]/)) {
        score += 1 // require special characters
      }

      this.score = score
    },
    resetPassword() {
      this.$store.commit('clearError');

      if (this.score < 5) {
        this.$store.commit('setError', 'Password is too weak. Please include at least 8 characters, uppercase letters, lowercase letters, numbers, and special characters.')
        return
      }

      if (this.password !== this.password_confirm) {
        this.$store.commit('setError', 'Passwords do not match.')
        return
      }

      this.$store.dispatch('resetPassword', {
        email: this.$route.query.email,
        code: this.$route.query.code,
        password: this.password
      }).then((valid) => {
        if (valid) {
          this.$store.commit('setLoginMessage', 'Password reset successfully. Please login.')
          this.$router.push('/login')
        }
      })
    }
  },
  metaInfo() {
    return {
      title: 'Reset Password'
    }
  }
}
</script>
