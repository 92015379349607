<template>
  <div>

    <div v-if="error" class="error" v-html="error"></div>


    <div class="loading" v-if="loading">
      <div class="wrapper">
        <span class="spinner"></span>
        <p class="sr-only">Loading...</p>
      </div>
    </div>

    <div class="wrapper events-list" v-else>
      <header class="events-list--header">
        <h1>Events</h1>
      </header>

      <div class="events-list--grid" v-if="events.length > 0">
        <EventThumbnail v-for="event in events" :key="event.id" :event="event" />
      </div>
      <div v-else>
        <p>Check back soon for future events</p>
      </div>
    </div>
  </div>
</template>

<script>
import EventThumbnail from '@/components/EventThumbnail'

export default {
  name: 'EventsList',
  components: {
    EventThumbnail
  },
  mounted() {
    this.$store.dispatch('getEvents').then(() => {
      this.loading = false
      this.events = this.$store.getters.getEvents
    })
  },
  computed: {
    error() {
      return this.$store.getters.getError
    }
  },
  data() {
    return {
      loading: true,
      events: []
    }
  },
  methods: {
  },
  metaInfo() {
    return {
      title: 'Upcoming Events'
    }
  }
}
</script>
