<template>
  <div>

    <div v-if="error" class="error" v-html="error"></div>

    <div class="wrapper" v-if="sponsor">
      <form @submit.prevent="updateUser" class="form">
        <p><router-link to="/change-password">Change Your Password</router-link></p>

        <h1>Edit Business Society Toronto Profile</h1>

        <h2>Sponsor Information</h2>

        <FormGroup label="Name" v-model="sponsor.name" :errors="errors.name" @errors="updateErrors" :required="true" />

        <fieldset class="columns">

          <FormGroup label="Company" v-model="sponsor.acf.company" :errors="errors.company" @errors="updateErrors" />

          <FormGroup label="Company URL" type="url" v-model="sponsor.acf.company_url" :errors="errors.company_url" @errors="updateErrors"/>
        </fieldset>

        <fieldset class="columns">
          <FormGroup label="Email Address" v-model="sponsor.meta.email" :readonly="true" />

          <FormGroup label="Phone" type="tel" v-model="sponsor.acf.telephone" :errors="errors.phone" @errors="updateErrors"/>
        </fieldset>

        <div class="form-group form-group--image" :class="{ 'form-group--error': errors.member_image }">

          <label for="member_image">Sponsor Image</label>

          <span :class="{ 'loading': member_image_loading }" v-if="member_image !== 'remove'">
            <template v-if="member_image_loading">
              <span class="spinner"></span>
              <p class="sr-only">Loading...</p>
            </template>
            <img v-if="member_image || sponsor.acf_json.member_image" :src="member_image ? member_image_url : sponsor.acf_json.member_image.url" />
          </span>
          <input type="file" id="member_image" @change="addImage($event, 'member_image')" />

          <div v-if="errors.member_image" class="error-message">{{ errors.member_image }}</div>

          <button type="button" @click="removeImage('member_image')" v-if="(member_image && member_image !== 'remove') || sponsor.acf.member_image" class="btn min">
            <FontAwesomeIcon :icon="icon.minus" />
            Remove Image
          </button>
        </div>

        <h2>Social profiles</h2>

        <fieldset class="columns" v-for="n of [1,2,3,4]" v-bind:key="n">
          <FormGroup :fieldName="'social_media__' + n" type="select" v-model="sponsor.acf['social_' + n].social_media" :errors="errors['social_media__' + n]" @errors="updateErrors" :options="social_media" :readonly="true" />
          <FormGroup :fieldName="'profile_url_' + n" type="url" v-model="sponsor.acf['social_' + n].profile_url" :errors="errors['profile_url_' + n]" @errors="updateErrors" />
        </fieldset>

        <h2>Business Information</h2>

        <FormGroup label="Business Information" type="textarea" v-model="sponsor.acf.business_information" :errors="errors.business_information" @errors="updateErrors" />

        <h2>Forum Speaking Topics</h2>

        <FormGroup label="Forum Speaking Topics" type="textarea" v-model="sponsor.acf.forum_speaking_topics" :errors="errors.forum_speaking_topics" @errors="updateErrors" helperText="One topic per line, maximum 5 topics" />

        <div class="form-footer">
          <button type="submit" class="btn lrg">Update Profile</button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import FormGroup from '@/components/FormGroup.vue';
import { faPlus, faMinus } from '@fortawesome/free-solid-svg-icons';
import DOMPurify from 'dompurify';

export default {
  name: 'EditSponsorProfile',
  components: {
    FormGroup
  },
  mounted() {
    this.$store.dispatch('getSponsor', this.$route.params.slug).then(() => {
      this.sponsor = structuredClone(this.$store.getters.getSponsor(this.$route.params.slug))

      if (!this.sponsor) {
        this.$router.push('/sponsors')
        return;
      }

      if (this.user && !this.user.roles.includes('administrator') && (!this.user.roles.includes('sponsor') || this.user.slug !== this.$route.params.slug)) {
        this.$router.push('/sponsors/' + this.sponsor.slug)
      }

      this.member_image_id = this.sponsor.acf.member_image
      this.initialSponsorName = this.sponsor.name
    })
  },
  computed: {
    error() {
      return this.$store.getters.getError
    },
    user() {
      return this.$store.getters.getUser
    },
    member_image_url() {
      if (this.member_image === 'remove')
        return null
      return URL.createObjectURL(this.member_image)
    },
    social_media() {
      return this.$store.getters.getSocialMedia
    },
    years() {
      const currentYear = new Date().getFullYear()
      return Array.from({length: 100}, (v, k) => currentYear - k).map(year => {
        return { value: year, name: year }
      })
    }
  },
  data() {
    return {
      icon: {
        plus: faPlus,
        minus: faMinus
      },
      initialSponsorName: '',
      sponsor: false,
      member_image: false,
      member_image_loading: false,
      member_image_id: null,
      errors: {}
    }
  },
  methods: {
    addImage(ev, id) {
      ev.preventDefault()
      delete this.errors[id]

      const file = ev.target.files[0]
      const maxSize = 4 * 1024 * 1024 // 4MB
      const allowedFormats = ['image/jpeg', 'image/png', 'image/gif']

      if (file.size > maxSize) {
        this.errors[id] = 'File size exceeds the maximum limit of 4MB.'
        console.log(file.size, id, this.errors)
        this.$forceUpdate()
        return
      }

      if (!allowedFormats.includes(file.type)) {
        this.errors[id] = 'Invalid file format. Only JPEG, PNG, and GIF images are allowed.'
        console.log(file.type, id, this.errors)
        this.$forceUpdate()
        return
      }

      this[id] = file
      this[id + '_loading'] = true
      this.$store.dispatch('addMedia', this[id]).then((attachment) => {
        this[id + '_loading'] = false
        this[id + '_id'] = attachment.id
      });
    },
    updateUser() {
      this.$store.commit('setError', false)

      if (Object.keys(this.errors).length > 0) {
        this.$store.commit('setError', 'Please correct errors before submitting')
        return
      }

      if (this.member_image_loading === null) {
        this.$store.commit('setError', 'Please wait for the image(s) to finish uploading')
        return
      }

      const sponsor = {
        id: this.sponsor.id,
        name: DOMPurify.sanitize(this.sponsor.name),
        acf: {
          company: DOMPurify.sanitize(this.sponsor.acf.company),
          company_url: DOMPurify.sanitize(this.sponsor.acf.company_url),
          telephone: DOMPurify.sanitize(this.sponsor.acf.telephone),
          social_1: {
            social_media: DOMPurify.sanitize(this.sponsor.acf.social_1.social_media),
            profile_url: DOMPurify.sanitize(this.sponsor.acf.social_1.profile_url)
          },
          social_2: {
            social_media: DOMPurify.sanitize(this.sponsor.acf.social_2.social_media),
            profile_url: DOMPurify.sanitize(this.sponsor.acf.social_2.profile_url)
          },
          social_3: {
            social_media: DOMPurify.sanitize(this.sponsor.acf.social_3.social_media),
            profile_url: DOMPurify.sanitize(this.sponsor.acf.social_3.profile_url)
          },
          social_4: {
            social_media: DOMPurify.sanitize(this.sponsor.acf.social_4.social_media),
            profile_url: DOMPurify.sanitize(this.sponsor.acf.social_4.profile_url)
          },
          business_information: DOMPurify.sanitize(this.sponsor.acf.business_information),
          forum_speaking_topics: DOMPurify.sanitize(this.sponsor.acf.forum_speaking_topics)
        }
      }

      if (this.member_image == 'remove') {
        sponsor.acf.member_image = null
      } else if (this.member_image) {
        sponsor.acf.member_image = this.member_image_id
      }

      this.$store.dispatch('updateUser', sponsor).then(() => {
        if (!this.error) {
          this.sponsor = this.$store.getters.getSponsorById(this.sponsor.id)
          this.$router.push('/sponsors/' + this.sponsor.slug).then(() => {
            if (this.initialSponsorName !== this.sponsor.name && this.user.id === this.sponsor.id) {
              this.$store.commit('login', this.sponsor)
            }
          })
        }
      }).catch((error) => {
        this.$store.commit('setError', error)
      });
    },
    updateErrors(errors) {
      if (errors[1] === false) {
        delete this.errors[errors[0]]
      } else {
        this.errors[errors[0]] = errors[1]
      }
    },
    removeImage(id) {
      if (confirm("Are you sure you want to remove the image?")) {
        this.sponsor.acf[id] = null
        this.sponsor.acf_json[id] = null
        this[id] = 'remove'
        this[id + '_id'] = null
      }
    }
  }
}
</script>
