<template>
  <div>
    <div class="wrapper">
      <h1>Page Not Found</h1>
      <p><router-link to="/">Return Home</router-link></p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HomePage',
  data() {
    return {
    }
  },
  methods: {
  }
}
</script>
