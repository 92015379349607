<template>
  <GmapMap
    :center="position"
    :zoom="13"
    :options="{
      draggable: true,
      zoomControl: true,
      mapTypeControl: false,
      scaleControl: false,
      streetViewControl: false,
      rotateControl: false,
      fullscreenControl: false,
      disableDefaultUi: false,
      styles: mapStyle
    }"
  >
    <GmapMarker
      :icon="{
        url: require('@/assets/map-marker.png'),
        scaledSize: google && new google.maps.Size(35, 45)
      }"
      :position="position"
      :clickable="true"
      :draggable="false"
      @click="openDirections"
    />
  </GmapMap>
</template>

<script>
import {gmapApi} from 'vue2-google-maps'

export default {
  name: 'InlineMap',
  props: {
    location: {
      type: Object,
      required: true
    },
    directions: {
      type: String,
      default: ''
    }
  },
  computed: {
    google: gmapApi,
    position: {
      get() {
        return {
          lat: Number(this.location.latitude),
          lng: Number(this.location.longitude)
        }
      }
    }
  },
  data() {
    return {
      currentMidx: null,
      infoOptions: {
        pixelOffset: {
          width: 0,
          height: -50
        }
      },
      mapStyle: [
          {
              "featureType": "all",
              "elementType": "geometry.fill",
              "stylers": [
                  {
                      "weight": "2.00"
                  }
              ]
          },
          {
              "featureType": "all",
              "elementType": "geometry.stroke",
              "stylers": [
                  {
                      "color": "#9c9c9c"
                  }
              ]
          },
          {
              "featureType": "all",
              "elementType": "labels.text",
              "stylers": [
                  {
                      "visibility": "on"
                  }
              ]
          },
          {
              "featureType": "landscape",
              "elementType": "all",
              "stylers": [
                  {
                      "color": "#f2f2f2"
                  }
              ]
          },
          {
              "featureType": "landscape",
              "elementType": "geometry.fill",
              "stylers": [
                  {
                      "color": "#ffffff"
                  }
              ]
          },
          {
              "featureType": "landscape.man_made",
              "elementType": "geometry.fill",
              "stylers": [
                  {
                      "color": "#ffffff"
                  }
              ]
          },
          {
              "featureType": "poi",
              "elementType": "all",
              "stylers": [
                  {
                      "visibility": "off"
                  }
              ]
          },
          {
              "featureType": "road",
              "elementType": "all",
              "stylers": [
                  {
                      "saturation": -100
                  },
                  {
                      "lightness": 45
                  }
              ]
          },
          {
              "featureType": "road",
              "elementType": "geometry.fill",
              "stylers": [
                  {
                      "color": "#eeeeee"
                  }
              ]
          },
          {
              "featureType": "road",
              "elementType": "labels.text.fill",
              "stylers": [
                  {
                      "color": "#7b7b7b"
                  }
              ]
          },
          {
              "featureType": "road",
              "elementType": "labels.text.stroke",
              "stylers": [
                  {
                      "color": "#ffffff"
                  }
              ]
          },
          {
              "featureType": "road.highway",
              "elementType": "all",
              "stylers": [
                  {
                      "visibility": "simplified"
                  }
              ]
          },
          {
              "featureType": "road.arterial",
              "elementType": "labels.icon",
              "stylers": [
                  {
                      "visibility": "off"
                  }
              ]
          },
          {
              "featureType": "transit",
              "elementType": "all",
              "stylers": [
                  {
                      "visibility": "off"
                  }
              ]
          },
          {
              "featureType": "water",
              "elementType": "all",
              "stylers": [
                  {
                      "color": "#46bcec"
                  },
                  {
                      "visibility": "on"
                  }
              ]
          },
          {
              "featureType": "water",
              "elementType": "geometry.fill",
              "stylers": [
                  {
                      "color": "#c8d7d4"
                  }
              ]
          },
          {
              "featureType": "water",
              "elementType": "labels.text.fill",
              "stylers": [
                  {
                      "color": "#070707"
                  }
              ]
          },
          {
              "featureType": "water",
              "elementType": "labels.text.stroke",
              "stylers": [
                  {
                      "color": "#ffffff"
                  }
              ]
          }
      ]
    }
  },
  methods: {
    openDirections() {
      window.open(this.directions, '_blank')
    }
  }
}
</script>
