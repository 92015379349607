<template>
  <div>
    <h1>Logging out...</h1>
  </div>
</template>

<script>
export default {
  name: 'LogoutPage',
  data() {
    return {
    }
  },
  mounted() {
    this.$store.dispatch('logout').then(() => {
      this.$router.push('/login')
    })
  }
}
</script>
