<template>
  <div id="app" :class="appClass">
    <a href="#main-content" class="skip-link">Jump to main content</a>

    <SiteHeader :loading="loading" />
    <router-view id="main-content" />
    <SiteFooter />
  </div>
</template>

<script>
import SiteHeader from './components/SiteHeader.vue'
import SiteFooter from './components/SiteFooter.vue'

export default {
  name: 'App',
  components: {
    SiteHeader,
    SiteFooter
  },
  mounted() {
    this.$store.dispatch('checkAuth').then((valid) => {
      this.loading = false
      if (valid) {
        console.log('Logged in')
      }
    })
  },
  data() {
    return {
      loading: true
    }
  },
  computed: {
    error() {
      return this.$store.getters.getError
    },
    appClass() {
      return this.$route.name
    }
  },
  metaInfo: {
    title: 'Business Society Toronto',
    titleTemplate: '%s : Society Toronto'
  }
}
</script>

<style lang="scss" src="./styles/app.scss"></style>
