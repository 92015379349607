<template>
  <div>
    <div class="wrapper">

      <div v-if="error" class="error" v-html="error"></div>
      <div v-else-if="message" class="message" v-html="message"></div>

      <form @submit.prevent="requestReset" class="form">
        <h1>Forgot Password?</h1>

        <div class="form-group">
          <label for="email">E-mail Address</label>
          <input type="email" id="email" v-model="email" required>
        </div>
        <div class="form-footer">
          <button type="submit" class="btn">Request Password Reset</button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ForgotPassword',
  computed: {
    error() {
      return this.$store.getters.getError
    }
  },
  mounted() {
    this.$store.commit('clearError')
    this.$store.dispatch('checkAuth').then((valid) => {
      if (valid) {
        // already logged in
        this.$router.push('/')
      }
    })
  },
  data() {
    return {
      email: '',
      message: ''
    }
  },
  methods: {
    requestReset() {
      this.$store.dispatch('forgotPassword', {
        email: this.email
      }).then((valid) => {
        if (valid) {
          this.message = 'Password reset requested. Check your email for instructions.'
        }
      })
    }
  },
  metaInfo() {
    return {
      title: 'Forgot your Password?'
    }
  }
}
</script>
