<template>
  <div class="form-group" :class="{ 'form-group--error' : errors }">
    <label :for="field" v-if="label">{{ label }}</label>

    <input v-if="type === 'url'" type="url" :id="field" v-model="inputVal" @input="validateURL" :readonly="readonly" />

    <input v-else-if="type === 'tel'" type="tel" :id="field" v-model="inputVal" @input="validateTel" :readonly="readonly" />

    <div class="select" :class="{ 'select--icon': selectedIcon }" v-else-if="type === 'select'">
      <select :id="field" v-model="inputVal" :disabled="readonly">
        <option value="" v-if="showEmpty"></option>
        <option v-for="option in options" :value="option.value" :key="option.value">{{ option.name }}</option>
      </select>

      <FontAwesomeIcon v-if="selectedIcon" :icon="selectedIcon" class="icon" />

      <FontAwesomeIcon class="drop" :icon="down" v-if="!readonly" />
    </div>

    <textarea v-else-if="type === 'textarea'" maxlength="2200" :id="field" v-model="inputVal" @keyup="textAreaAdjust" :readonly="readonly"></textarea>

    <input v-else type="text" :id="field" v-model="inputVal" :readonly="readonly" @input="validateRequired" />

    <div v-if="helpText" class="help-text">{{ helpText }}</div>

    <div v-if="errors" class="error-message">{{ errors }}</div>
  </div>
</template>

<script>
import { faCaretDown } from '@fortawesome/free-solid-svg-icons';

export default {
  name: 'FormGroup',
  props: {
    label: {
      type: String
    },
    type: {
      type: String,
      default: 'text'
    },
    helperText: {
      type: String,
      default: ''
    },
    value: {
      type: [String, Number, Boolean],
      default: ''
    },
    errors: {
      type: String,
      default: ''
    },
    readonly: {
      type: Boolean,
      default: false
    },
    options: {
      type: Array,
      default: () => []
    },
    fieldName: {
      type: String,
      default: ''
    },
    showEmpty: {
      type: Boolean,
      default: true
    },
    required: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    inputVal: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
      }
    },
    field() {
      return this.fieldName || this.label.toLowerCase().replace(' ', '_')
    },
    selectedIcon() {
      const selectedOption = this.options.find(option => option.value === this.inputVal)
      if (selectedOption && selectedOption.icon) {
        return selectedOption.icon
      } else {
        return false
      }
    },
    helpText() {
      if (this.helperText) {
        return this.helperText
      } else if (this.type === 'tel') {
        return 'Format: 000-000-0000'
      } else if(this.type === 'textarea') {
        return this.inputVal.length + ' / 2200 characters'
      } else {
        return false
      }
    }
  },
  data() {
    return {
      down: faCaretDown
    }
  },
  methods: {
    textAreaAdjust(ev) {
      const element = ev.target;
      element.style.height = "1px";
      element.style.height = (25+element.scrollHeight)+"px";
    },
    validateRequired(ev) {
      const element = ev.target;

      this.$emit('errors', [
        this.field, false
      ]);

      if (this.required && element.value.length === 0) {
        this.$emit('errors', [
          this.field, 'This field is required.'
        ]);
      }
    },
    validateURL(ev) {
      const element = ev.target;

      if (this.required) {
        this.validateRequired(ev);
      }

      this.$emit('errors', [
        this.field, false
      ]);

      if (element.value.length === 0) {
        return;
      }

      if (element.value.length > 8 && !element.value.startsWith('http://') && !element.value.startsWith('https://')) {
        this.inputVal = 'https://' + element.value;
      }

      if (!element.value.match(/(http(s)?:\/\/.)?([A-Za-z0-9-]+\.)+[A-Za-z]{2,}\/?/)) {
        this.$emit('errors', [
          this.field, 'Please enter a valid URL.'
        ]);
      }
    },
    validateTel(ev) {
      const element = ev.target;

      this.$emit('errors', [
        this.field, false
      ]);

      if (element.value.length === 0) {
        return;
      }

      const phoneNumber = element.value.replace(/[^\d]/g, ''); // Remove non-digit characters
      let formattedPhoneNumber = phoneNumber.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3'); // Format as 000-000-0000

      if (phoneNumber.length > 10) {
        const extension = phoneNumber.slice(10);
        formattedPhoneNumber = formattedPhoneNumber.slice(0, 12);
        formattedPhoneNumber += ` ext ${extension}`;
      }


      this.inputVal = formattedPhoneNumber;

      if (!formattedPhoneNumber.match(/(^[\d-]{12}$|^[\d-]{12} ext \d{2,4}$)/)) {
        this.$emit('errors', [
          this.field, 'Please enter a valid phone number.'
        ]);
      }
    }
  }
}
</script>
