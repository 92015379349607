<template>
  <div class="sponsor-thumbnail">
    <router-link :to="'/sponsors/' + sponsor.slug">
      <img v-if="sponsor.acf_json && sponsor.acf_json.member_image" :src="sponsor.acf_json.member_image.sizes.thumbnail" />
      <div class="image-placeholder" v-else>
        <span><FontAwesomeIcon :icon="icon.user" class="user-icon" /></span>
      </div>
      <h2 v-html="`${sponsor.name} <span class='company'>${sponsor.acf.company}</span>`"></h2>
      <slot name="guestcount"></slot>
    </router-link>
  </div>
</template>

<script>
import { faUser } from '@fortawesome/free-solid-svg-icons';

export default {
  name: 'SponsorThumbnail',
  props: {
    sponsor: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      icon: {
        user: faUser
      }
    }
  },
  methods: {
    twoLines(name) {
      // Split the name at the middle, or at the last space before the middle
      const middleIndex = Math.floor(name.length / 2);
      const spaceIndex = name.lastIndexOf(' ', middleIndex);
      if (spaceIndex !== -1) {
        return name.substring(0, spaceIndex) + '<br />' + name.substring(spaceIndex + 1);
      } else {
        return name.replace(' ', '<br />');
      }
    }
  }
}
</script>
