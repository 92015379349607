<template>
  <header class="banner" role="banner">
    <nav role="navigation" class="wrapper">
      <router-link to="/" class="brand">
        <inline-svg :src="require('@/assets/logo-society.svg')" />
      </router-link>

      <button @click="toggleMenu" class="menu-toggle" :aria-expanded="menuOpen ? true : false" aria-controls="main-menu">
        <FontAwesomeIcon :icon="menuOpen ? close : bars" />
      </button>

      <div class="main-menu" :class="{ 'open': menuOpen }" id="main-menu">
        <router-link v-if="isLoggedIn" to="/members">Members</router-link>
        <router-link v-if="isLoggedIn" to="/events">Events</router-link>
        <!-- <router-link v-if="isLoggedIn" to="/sponsors-page">Sponsors (Old)</router-link> -->
        <router-link v-if="isLoggedIn" to="/sponsors">Sponsors</router-link>
        <router-link v-if="isLoggedIn && user.roles.includes('member')" :to="`/members/${user.slug}`">My Profile <span>({{ user.name }})</span></router-link>
        <router-link v-if="isLoggedIn && user.roles.includes('sponsor')" :to="`/sponsors/${user.slug}`">My Profile <span>({{ user.name }})</span></router-link>
        <router-link v-if="isLoggedIn" to="/logout">Logout</router-link>

        <router-link v-if="!loading && !isLoggedIn" to="/login" class="btn">User Login</router-link>
      </div>
    </nav>
  </header>
</template>

<script>
import { faBars, faTimes } from '@fortawesome/free-solid-svg-icons';

export default {
  name: 'SiteHeader',
  props: {
    loading: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    user() {
      return this.$store.getters.getUser
    },
    isLoggedIn() {
      return this.$store.getters.isLoggedIn
    }
  },
  watch: {
    '$route'() {
      this.menuOpen = false
    }
  },
  data() {
    return {
      bars: faBars,
      close: faTimes,
      menuOpen: false
    }
  },
  methods: {
    toggleMenu() {
      this.menuOpen = !this.menuOpen
    }
  }
}
</script>
