<template>
  <div class="event-thumbnail">
    <router-link :to="'/events/' + event.slug">
      <div class="event-thumbnail__image">
        <img v-if="event.image" :src="event.image.sizes.medium" :alt="event.image.alt" />
      </div>

      <div class="event-thumbnail--content">

        <h2>{{ event.title }}</h2>

        <p>
          <strong>
            <FontAwesomeIcon :icon="icon.calendar" />
            Event Date
          </strong>
          <span class="from">{{ event.event_start | moment(dateFormat) }}</span>
          <span v-if="event.event_end !== event.event_start" class="to"> - {{ event.event_end | moment(dateFormat) }}</span>
        </p>

        <p v-if="event.event_start_time">
          <strong>
            <FontAwesomeIcon :icon="icon.clock" />
            Event Time
          </strong>
          <span class="from">{{ event.event_start + ' ' + event.event_start_time | moment(hoursFormat) }}</span>
          <span class="to"> - {{ event.event_end + ' ' + event.event_end_time | moment(hoursFormat) }}</span>
        </p>

        <p v-if="event.location">
          <strong>
            <FontAwesomeIcon :icon="icon.mapMarker" />
            {{ event.location.name }}
          </strong>
        </p>

        <p v-html="event.excerpt"></p>

        <p><span class="btn">More Info</span></p>
      </div>
    </router-link>
  </div>
</template>

<script>
import { faLocationDot } from '@fortawesome/free-solid-svg-icons';
import { faCalendarDays, faClock } from '@fortawesome/free-regular-svg-icons';

export default {
  name: 'EventThumbnail',
  props: {
    event: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      icon: {
        calendar: faCalendarDays,
        clock: faClock,
        mapMarker: faLocationDot
      },
      dateFormat: 'MMMM D, YYYY',
      hoursFormat: 'h:mm a'
    }
  }
}
</script>
