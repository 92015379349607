import axios from 'axios'
import Vue from 'vue'
import Vuex from 'vuex'
import { faFacebookF, faInstagram, faLinkedin, faXTwitter } from '@fortawesome/free-brands-svg-icons';
Vue.use(Vuex)

if (process.env.NODE_ENV === 'development') {
  axios.defaults.baseURL = 'https://societytoronto.com';
}

if (document.cookie.includes('jwt_auth')) {
  axios.defaults.headers.common['Authorization'] = `Bearer ${document.cookie.split(';').find(cookie => cookie.includes('jwt_auth')).split('=')[1]}`
}

const getters = {
  allPages: state => state.pages,
  getHomepage: state => state.pages.find(page => page.homepage),
  getPage: state => slug => state.pages.find(page => page.slug === slug),
  getMembers: state => state.members,
  getMember: state => slug => state.members.find(member => member.slug === slug),
  getMemberById: state => id => state.members.find(member => member.id === id),
  getSponsors: state => state.sponsors,
  getSponsor: state => slug => state.sponsors.find(sponsor => sponsor.slug === slug),
  getSponsorById: state => id => state.sponsors.find(sponsor => sponsor.id === id),
  getEvents: state => state.events,
  getEvent: state => slug => state.events.find(event => event.slug === slug),
  getError: state => state.error,
  getLoginMessage: state => state.loginMessage,
  getSocialMedia: state => state.social_media,
  isLoggedIn: state => state.logged_in,
  getUser: state => state.user
}

const state = {
  error: false,
  loginMessage: false,
  pages: [],
  members: [],
  sponsors: [],
  events: [],
  logged_in: false,
  user: {},
  social_media: [
    {
      name: 'Facebook',
      value: 'facebook',
      icon: faFacebookF
    },
    {
      name: 'X (Twitter)',
      value: 'x',
      icon: faXTwitter
    },
    {
      name: 'Instagram',
      value: 'instagram',
      icon: faInstagram
    },
    {
      name: 'LinkedIn',
      value: 'linkedin',
      icon: faLinkedin
    }
  ]
}

const actions = {
  async getHomepage({commit}){
    commit('clearError')
    const response = await axios.get('/wp-json/wpheadless/v1/frontpage').catch(e => {
      state.error = e.response
    })
    if (response) {
      commit('setHomepage', response.data)
    } else {
      state.error = 'Error fetching homepage data.'
    }
  },
  async getPage({commit}, slug){
    commit('clearError')
    if (getters.allPages(state).find(page => page.slug === slug)) {
      return getters.allPages(state).find(page => page.slug === slug)
    }
    const response = await axios.get(`/wp-json/wp/v2/pages?slug=${slug}`).catch(e => {
      state.error = e.response
    })
    if (response) {
      commit('setPages', response.data)
    } else {
      state.error = 'Error fetching page data.'
    }
  },
  async getMembers({commit}){
    commit('clearError')
    const response = await axios.get('/wp-json/wp/v2/users', {
      params: {
        roles: 'member',
        per_page: 100
      }
    }).catch(e => {
      state.error = e.response
    })
    if (response) {
      commit('setMembers', response.data)
    } else {
      state.error = 'Error fetching members.'
    }
  },
  async getSponsors({commit}){
    commit('clearError')
    const response = await axios.get('/wp-json/wp/v2/users', {
      params: {
        roles: 'sponsor',
        per_page: 100
      }
    }).catch(e => {
      state.error = e.response
    })
    if (response) {
      commit('setSponsors', response.data)
    } else {
      state.error = 'Error fetching sponsors.'
    }
  },
  async getMember({commit}, slug){
    commit('clearError')
    if (getters.getMember(state)(slug)) {
      return getters.getMember(state)(slug)
    }
    const response = await axios.get(`/wp-json/wp/v2/users?user-slug=${slug}&per_page=1`).catch(e => {
      state.error = e.response
    })
    if (response) {
      commit('setMembers', response.data)
    } else {
      state.error = 'Error fetching member data.'
    }
  },
  async getSponsor({commit}, slug){
    commit('clearError')
    if (getters.getSponsor(state)(slug)) {
      return getters.getSponsor(state)(slug)
    }
    const response = await axios.get(`/wp-json/wp/v2/users?user-slug=${slug}&per_page=1`).catch(e => {
      state.error = e.response
    })
    if (response) {
      commit('setSponsors', response.data)
    } else {
      state.error = 'Error fetching sponsor data.'
    }
  },
  async getEvents({commit}){
    commit('clearError')
    const response = await axios.get('/wp-json/wpheadless/v1/event').catch(e => {
      state.error = e.response
    })
    if (response) {
      commit('setEvents', response.data)
    } else {
      state.error = 'Error fetching events.'
    }
  },
  async getEvent({commit}, slug){
    commit('clearError')
    if (getters.getEvent(state)(slug)) {
      return getters.getEvent(state)(slug)
    }
    const response = await axios.get(`/wp-json/wpheadless/v1/event/${slug}`).catch(e => {
      state.error = e.response
    })
    if (response) {
      commit('setEvents', response.data)
    } else {
      state.error = 'Error fetching event data.'
    }
  },
  async addMedia({commit}, image){
    commit('clearError')
    const response = await axios.post('/wp-json/wp/v2/media', image, {
      headers: {
        'Content-Disposition': 'attachment; filename="'+image.name+'"'
      }
    }).catch(e => {
      state.error = e.response.data.message || e.message
    })
    if (response) {
      return response.data
    }
  },
  async updateUser({commit}, user){
    commit('clearError')
    const response = await axios.post(`/wp-json/wp/v2/users/${user.id}`, user, {
      headers: {
        'Content-Type': 'application/json'
      }
    }).catch(e => {
      state.error = e.response.data.message || e.message
    })
    if (response) {
      if(response.data.roles.includes('member')){
        commit('setMembers', [response.data])
      } else if(response.data.roles.includes('sponsor')){
        commit('setSponsors', [response.data])
      }
    }
  },
  async submitForm({commit}, form){
    commit('clearError')
    const response = await axios.post(`/wp-json/contact-form-7/v1/contact-forms/${form.formID}/feedback`, form.formData).catch(e => {
      state.error = e.response.data.message
    })
    if (response) {
      return true
    }
  },
  async bookEvent({commit}, booking){
    commit('clearError');
    const response = await axios.post('/wp-json/wpheadless/v1/booking', booking).catch(e => {
      state.error = e.response
      return false
    })
    if (response) {
      return response.data
    }
  },
  async cancelBooking({commit}, booking){
    commit('clearError');
    const response = await axios.delete(`/wp-json/wpheadless/v1/booking/${booking.id}`).catch(e => {
      state.error = e.response.data.message
      return false
    })
    if (response) {
      return true
    } else {
      return false
    }
  },
  async login({commit}, credentials){
    commit('clearError')
    const response = await axios.post('/wp-json/jwt-auth/v1/token', credentials).catch(e => {
      state.error = e.response.data.message
      commit('logout')
      return false
    })
    if (response) {
      commit('login', response.data)
      document.cookie = `jwt_auth=${response.data.token}; path=/;`
      axios.defaults.headers.common['Authorization'] = `Bearer ${response.data.token}`
      const userResponse = await axios.post('/wp-json/wp/v2/users/me').catch(e => {
        console.log(e.response)
      })
      commit('login', userResponse.data)
      commit('setLoginMessage', '')
      return true
    } else {
      commit('logout')
    }
  },
  async checkAuth({commit}){
    commit('clearError')
    if (state.logged_in) {
      return true
    }
    const response = await axios.post('/wp-json/jwt-auth/v1/token/validate').catch(() => {
      commit('logout')
    })
    if (response) {
      const userResponse = await axios.post('/wp-json/wp/v2/users/me').catch(e => {
        console.log(e.response)
      })
      if (userResponse) {
        commit('login', userResponse.data)
        return true
      } else {
        commit('logout')
        return false
      }
    } else {
      commit('logout')
      return false
    }
  },
  async logout({commit}){
    commit('clearError')
    commit('logout')
    commit('setLoginMessage', 'You have been logged out')
    return true
  },
  async forgotPassword({commit}, credentials){
    commit('clearError')
    const response = await axios.post('/wp-json/bdpwr/v1/reset-password', credentials).catch(e => {
      state.error = e.response.data.message
      return false
    })
    if (response) {
      return true
    }
  },
  async verifyResetCode({commit}, params){
    commit('clearError')
    const response = await axios.post('/wp-json/bdpwr/v1/validate-code', params).catch(() => {
      return false
    })
    if (response) {
      return true
    }
  },
  async resetPassword({commit}, params){
    commit('clearError')
    const response = await axios.post('/wp-json/bdpwr/v1/set-password', params).catch(() => {
      return false
    })
    if (response) {
      return true
    }
  },
  async changePassword({commit}, user){
    commit('clearError')
    const response = await axios.post(`/wp-json/wp/v2/users/${user.id}`, user, {
      headers: {
        'Content-Type': 'application/json'
      }
    }).catch(() => {
      return false
    })
    if (response) {
      // update user object in store, so that the user is not nagged to change their password
      state.user.meta.default_password_nag = false
      commit('login', response.data)
      return true
    }
  }
}

const mutations = {
  clearError: (state) => state.error = false,
  setError: (state, error) => state.error = error,
  setLoginMessage: (state, message) => state.loginMessage = message,
  setHomepage: (state, page) => {
    page.homepage = true,
    state.pages.push(page)
  },
  setPages: (state, pages) => {
    state.pages = state.pages.concat(pages)
  },
  setMembers: (state, members) => {
    state.members = members
  },
  setSponsors: (state, sponsors) => {
    state.sponsors = sponsors
  },
  setEvents: (state, events) => {
    state.events = events
  },
  login: (state, user) => {
    state.logged_in = true
    state.user = user
  },
  logout: (state) => {
    document.cookie = 'jwt_auth=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;'
    delete axios.defaults.headers.common['Authorization']
    state.members = []
    state.sponsors = []
    state.events = []
    state.logged_in = false
    state.user = {}
  }
}


export default new Vuex.Store({
  state,
  getters,
  actions,
  mutations
})
