import store from '@/store'

import HomePage from '@/views/HomePage'
import PrivacyPolicy from '@/views/PrivacyPolicy'
import SponsorsPage from '@/views/SponsorsPage'
import MembersList from '@/views/MembersList'
import MemberProfile from '@/views/MemberProfile'
import EditMemberProfile from '@/views/EditMemberProfile'
import SponsorsList from '@/views/SponsorsList'
import SponsorProfile from '@/views/SponsorProfile'
import EditSponsorProfile from '@/views/EditSponsorProfile'
import EventsList from '@/views/EventsList'
import EventPage from '@/views/EventPage'
import LoginPage from '@/views/auth/LoginPage'
import LogoutPage from '@/views/auth/LogoutPage'
import ForgotPassword from '@/views/auth/ForgotPassword'
import ResetPassword from '@/views/auth/ResetPassword'
import ChangePassword from '@/views/auth/ChangePassword'
import PageNotFound from '@/views/PageNotFound'

function requireAuth(to, from, next) {
  store.dispatch('checkAuth').then(() => {
    if (store.getters.isLoggedIn) {
      next();
    } else {
      next('/login?redirect=' + to.fullPath);
    }
  })
}

export default [
  // Home
  { path: '/', name: 'home', component: HomePage },

  { path: '/privacy-policy', name: 'privacy-policy', component: PrivacyPolicy },
  { path: '/sponsors-page', name: 'sponsors-page', component: SponsorsPage, beforeEnter: requireAuth },

  // Members
  { path: '/members', name: 'members', component: MembersList, beforeEnter: requireAuth },
  { path: '/members/:slug', name: 'member', component: MemberProfile, beforeEnter: requireAuth },
  { path: '/members/:slug/edit', name: 'edit-member', component: EditMemberProfile, beforeEnter: requireAuth },

  // Sponsors
  { path: '/sponsors', name: 'sponsors', component: SponsorsList, beforeEnter: requireAuth },
  { path: '/sponsors/:slug', name: 'sponsor', component: SponsorProfile, beforeEnter: requireAuth },
  { path: '/sponsors/:slug/edit', name: 'edit-sponsor', component: EditSponsorProfile, beforeEnter: requireAuth },

  // Events
  { path: '/events', name: 'events', component: EventsList, beforeEnter: requireAuth },
  { path: '/events/:slug', name: 'event', component: EventPage, beforeEnter: requireAuth },

  // Authentication routes
  { path: '/login', name: 'login', component: LoginPage },
  { path: '/logout', component: LogoutPage },
  { path: '/forgot-password', name: 'forgot-password', component: ForgotPassword },
  { path: '/reset-password', name: 'reset-password', component: ResetPassword },
  { path: '/change-password', name: 'change-password', component: ChangePassword },

  // 404
  { path: '/404', component: PageNotFound},
  { path: '*', component: PageNotFound}
]
