<template>
  <div>

    <div v-if="error" class="error" v-html="error"></div>

    <div v-if="member">
      <div class="lead-in">
        <div class="wrapper">
          <div class="lead-in--image">
            <img v-if="member.acf_json.member_image" a :src="member.acf_json.member_image.sizes.large" :width="member.acf_json.member_image.sizes['large-width']" :height="member.acf_json.member_image.sizes['large-height']" />
            <router-link :to="editLink" class="image-placeholder edit-link" v-else-if="canEdit">
              <span>
                <FontAwesomeIcon :icon="icon.edit" />
                Upload an Image
              </span>
            </router-link>
          </div>

          <div class="lead-in--content">
            <router-link v-if="canEdit" :to="editLink" class="edit-link edit-link--main">
              <FontAwesomeIcon :icon="icon.edit" />
              Edit Your Profile
            </router-link>

            <h1>{{ member.name }}</h1>

            <!-- Company -->
            <dl v-if="member.acf.company">
              <dt>Company:</dt>
              <dd>{{ member.acf.company }} <a :href="member.acf.company_url" target="_blank" v-if="member.acf.company_url">{{ cleanURL(member.acf.company_url) }}</a></dd>
            </dl>
            <dl v-else-if="canEdit">
              <dt>Company:</dt>
              <dd><router-link :to="editLink" class="edit-link">
                <FontAwesomeIcon :icon="icon.edit" />
                Add Company
              </router-link></dd>
            </dl>

            <!-- Phone -->
            <dl v-if="member.acf.telephone">
              <dt>Phone:</dt>
                <dd><a :href="`tel:+1${member.acf.telephone.replace('ext', ',').replaceAll(/[^0-9,]/g, '')}`">{{ member.acf.telephone }}</a></dd>
            </dl>
            <dl v-else-if="canEdit">
              <dt>Phone:</dt>
              <dd><router-link :to="editLink" class="edit-link">
                <FontAwesomeIcon :icon="icon.edit" />
                Add Telephone
              </router-link></dd>
            </dl>

            <!-- Email -->
            <dl v-if="member.meta.email">
              <dt>Email:</dt>
              <dd><a class="email-address" :href="`mailto:${member.meta.email}`">{{ member.meta.email }}</a></dd>
            </dl>

            <!-- Social -->
            <dl v-if="social_profiles.length > 0" class="social">
              <dt>Connect on Social:</dt>
              <dd>
                <span v-for="social, key in social_profiles" v-bind:key="key">
                  <a :href="social.profile_url" target="_blank" v-if="social.profile_url" :title="getSocialMedia(social.social_media).name">
                    <FontAwesomeIcon :icon="getSocialMedia(social.social_media).icon" :aria-label=" social.social_media" />
                  </a>
                </span>
              </dd>
            </dl>
            <dl v-else-if="canEdit" class="social">
              <dt>Connect on Social:</dt>
              <dd><router-link :to="editLink" class="edit-link">
                <FontAwesomeIcon :icon="icon.edit" />
                Add Social Profiles
              </router-link></dd>
            </dl>

          </div>
        </div>
      </div>
      <div class="body">
        <div class="wrapper" v-if="member.acf.personal_interests || member.acf_json.personal_image">
          <h2 class="body--heading">Personal Interests</h2>
          <div class="body--content">

            <div v-if="member.acf.personal_interests" v-html="autop(member.acf.personal_interests)"></div>

            <div v-else-if="canEdit">
              <router-link :to="editLink" class="edit-link">
                <FontAwesomeIcon :icon="icon.edit" />
                Add Personal Interests
              </router-link>
            </div>


            <div class="personal-image">
              <img v-if="member.acf_json.personal_image" a :src="member.acf_json.personal_image.sizes.large" :width="member.acf_json.personal_image.sizes['large-width']" :height="member.acf_json.personal_image.sizes['large-height']" />
              <router-link :to="editLink" class="image-placeholder edit-link" v-else-if="canEdit">
                <span>
                  <FontAwesomeIcon :icon="icon.edit" />
                  Upload an Image
                </span>
              </router-link>
            </div>
          </div>
        </div>
        <div class="wrapper" v-if="member.acf.business_information">
          <hr />
          <h2 class="body--heading">Business Information</h2>
          <div class="body--content">

            <div v-if="member.acf.business_information" v-html="autop(member.acf.business_information)"></div>

            <div v-else-if="canEdit">
              <router-link :to="editLink" class="edit-link">
                <FontAwesomeIcon :icon="icon.edit" />
                Add Business Information
              </router-link>
            </div>
          </div>
        </div>
        <div class="wrapper" v-if="myBusinesses.length > 0 || canEdit">
          <h3 class="body--heading">Other Businesses / Investments</h3>
          <div class="body--content">

            <ul v-if="myBusinesses.length > 0" class="businesses">
              <li v-for="business, key in myBusinesses" v-bind:key="key">
                <h4>{{ business.name }} <a :href="business.url" target="_blank" v-if="business.url">{{ cleanURL(business.url) }}</a></h4>
                <p v-if="business.description">{{ business.description }}</p>
                <p v-if="business.year" class="year">Since {{ business.year }}</p>
              </li>
            </ul>

            <div v-else-if="canEdit">
              <router-link :to="editLink" class="edit-link">
                <FontAwesomeIcon :icon="icon.edit" />
                Add Businesses
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faPenToSquare } from '@fortawesome/free-solid-svg-icons'

export default {
  name: 'MemberProfile',
  components: {
    FontAwesomeIcon
  },
  mounted() {
    this.$store.dispatch('getMember', this.$route.params.slug).then(() => {
      this.member = this.$store.getters.getMember(this.$route.params.slug)

      if (!this.member) {
        this.$router.push('/members')
      } else if(this.member.meta.role == 'sponsor') {
        this.$router.push('/sponsors/' + this.$route.params.slug)
      }
    })
  },
  computed: {
    error() {
      return this.$store.getters.getError
    },
    canEdit() {
      const user = this.$store.getters.getUser
      return user && (user.roles.includes('administrator') || user.id === this.member.id)
    },
    social_profiles() {
      return [this.member.acf.social_1, this.member.acf.social_2, this.member.acf.social_3].filter(social => social.profile_url !== '' && social.social_media !== '')
    },
    social_media() {
      return this.$store.getters.getSocialMedia
    },
    editLink() {
      return `/members/${this.member.slug}/edit`
    },
    myBusinesses() {
      if (!this.member.acf.businesses) return []
      return this.member.acf.businesses.filter(business => business.business_name !== '').sort((a, b) => a.year - b.year);
    }
  },
  watch: {
    $route(to) {
      this.$store.dispatch('getMember', to.params.slug).then(() => {
        this.member = this.$store.getters.getMember(to.params.slug)

        if (!this.member) {
          this.$router.push('/members')
        }
      })
    }
  },
  data() {
    return {
      member: false,
      icon: {
        edit: faPenToSquare
      }
    }
  },
  methods: {
    cleanURL(url) {
      url = url.replace(/(^\w+:|^)\/\//, '');
      url = url.replace(/^www\./, '');
      url = url.replace(/\/$/, '');
      return url;
    },
    getSocialMedia(val) {
      return this.social_media.find(social => social.value === val)
    },
    autop(content) {
      content = content.replace(/(?:\r\n|\r|\n)/g, '<br />')
      content = '<p>' + content.replace(/<br \/><br \/>/g, '</p><p>') + '</p>'
      return content
    }
  },
  metaInfo() {
    return {
      title: this.member ? this.member.name : 'Member Profile'
    }
  }
}
</script>
