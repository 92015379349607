<template>
  <div>

    <div v-if="error" class="error" v-html="error"></div>


    <div class="loading" v-if="loading">
      <div class="wrapper">
        <span class="spinner"></span>
        <p class="sr-only">Loading...</p>
      </div>
    </div>

    <div class="members-list" v-else>
      <header class="members-list--header">
        <div class="wrapper">
          <h1>Browse Business Society Toronto Members</h1>
        </div>
      </header>

      <div class="wrapper">
        <div class="members-list--search">
          <div class="form-group">
            <label for="search">Search</label>
            <div class="icon-before">
              <input type="search" id="search" v-model="search" />
              <FontAwesomeIcon :icon="icon.search" class="icon" />
            </div>
          </div>
        </div>

        <div class="members-list--grid">
          <MemberThumbnail v-for="member in filteredMembers" :key="member.id" :member="member" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MemberThumbnail from '@/components/MemberThumbnail'
import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons'

export default {
  name: 'MembersList',
  components: {
    MemberThumbnail
  },
  mounted() {
    this.$store.dispatch('getMembers').then(() => {
      this.loading = false
      this.members = this.$store.getters.getMembers
    })
  },
  computed: {
    error() {
      return this.$store.getters.getError
    },
    filteredMembers() {
      return this.members.filter(member => {
        return member.name.toLowerCase().includes(this.search.toLowerCase())
      }).sort((a, b) => a.name.localeCompare(b.name));
    }
  },
  data() {
    return {
      icon: {
        search: faMagnifyingGlass
      },
      loading: true,
      search: '',
      members: []
    }
  },
  methods: {
  },
  metaInfo() {
    return {
      title: 'Our Members'
    }
  }
}
</script>
