<template>
  <div>
    <div v-if="error" class="error" v-html="error"></div>

    <div class="loading" v-if="loading">
      <div class="wrapper">
        <span class="spinner"></span>
        <p class="sr-only">Loading...</p>
      </div>
    </div>

    <template v-else-if="page">
      <div class="lead-in">
        <div class="wrapper">
          <InlineVideo :video="page.acf.video_url" :poster="page.acf.video_poster" />

          <div class="lead-in--content" v-html="leadInContent"></div>
        </div>
      </div>

      <div class="body">
        <div v-html="bodyContent" class="wrapper"></div>
      </div>

      <div class="signup-form">
        <div class="wrapper">
          <div class="inner">
            <div v-html="formContent"></div>
            <form @submit.prevent="submitForm" class="form" :class="{'loading':submitting}">
              <span class="spinner" v-if="submitting"></span>

              <div v-html="form"></div>

              <div class="form-footer">
                <vue-recaptcha class="recaptcha" ref="recaptcha"
                  @verify="onVerify" :sitekey="recaptcha.site_key">
                </vue-recaptcha>

                <button type="submit" class="btn lrg" :disabled="submitting">Submit</button>

                <div v-if="formFeedback" class="form-feedback">{{ formFeedback }}</div>
                <div v-if="hasFormErrors" class="error-message">Please correct the errors above.</div>
              </div>
            </form>
          </div>

        </div>
      </div>

    </template>
  </div>
</template>

<script>
import { parse } from '@wordpress/block-serialization-default-parser';
import {VueRecaptcha} from 'vue-recaptcha';
import InlineVideo from '@/components/InlineVideo';

export default {
  name: 'HomePage',
  components: {
    VueRecaptcha,
    InlineVideo
  },
  mounted() {
    this.$store.dispatch('getHomepage').then(() => {
      this.page = this.$store.getters.getHomepage
      console.log(this.page);
      this.loading = false
    })
  },
  computed: {
    error() {
      return this.$store.getters.getError
    },
    form() {
      const html = this.page.content.form;
      const parser = new DOMParser();
      const doc = parser.parseFromString(html, 'text/html');
      const form = doc.querySelector('form');
      const formFooter = form.querySelector('.form-footer');
      if (formFooter) {
        formFooter.remove();
      }
      const innerContents = form.innerHTML;
      return innerContents;
    },
    parsedBlocks() {
      return parse(this.page.content.raw)
    },
    leadInContent() {
      return this.getBlockByName('lead-in')
    },
    bodyContent() {
      return this.getBlockByName('body')
    },
    formContent() {
      return this.getBlockByName('signup-form')
    }
  },
  data() {
    return {
      recaptcha: {
        site_key: '6LfvMaspAAAAAHMgxWpxqfdlAeYUYUPfyTg8mGhD'
      },
      recaptchaVerified: false,
      hasFormErrors: false,
      formFeedback: '',
      loading: true,
      submitting: false,
      page: false
    }
  },
  methods: {
    getInnerBlocks(block) {
      if (block.innerBlocks.length > 0) {
        return block.innerContent[0] +
          block.innerBlocks.map(this.getInnerBlocks).join('') +
          block.innerContent[block.innerContent.length - 1];
      } else {
        if (block.blockName === 'contact-form-7/contact-form-selector') {
          return "";
        }
        return block.innerHTML;
      }
    },
    getBlockByName(name) {
      return this.parsedBlocks.find(block => block.attrs.className === name).innerBlocks.map(this.getInnerBlocks).join('');
    },
    submitForm() {
      if (this.submitting) return
      this.submitting = true
      this.formFeedback = ''
      this.hasFormErrors = false
      const form = document.querySelector('.form');
      const errorMessages = document.querySelectorAll('.error-message');
      errorMessages.forEach(errorMessage => {
        errorMessage.remove();
      });
      const formElements = form.querySelectorAll('input, select, textarea');

      if (!this.recaptchaVerified) {
        this.hasFormErrors = true
        this.formFeedback = 'Please verify that you are not a robot.'
        this.submitting = false
        return
      }

      formElements.forEach(element => {
        if (element.classList.contains('wpcf7-validates-as-required') && element.value.trim() === '') {
          const formGroup = element.closest('.form-group');
          const errorMessage = document.createElement('div');
          errorMessage.classList.add('error-message');
          errorMessage.textContent = 'This field is required.';
          formGroup.appendChild(errorMessage);
          this.hasFormErrors = true
        }
        if (element.classList.contains('wpcf7-validates-as-email') && !/\S+@\S+\.\S+/.test(element.value)) {
          const formGroup = element.closest('.form-group');
          const errorMessage = document.createElement('div');
          errorMessage.classList.add('error-message');
          errorMessage.textContent = 'Please enter a valid email address.';
          formGroup.appendChild(errorMessage);
          this.hasFormErrors = true
        }
      });

      if (!this.hasFormErrors) {
        var bodyFormData = new FormData();
        formElements.forEach(element => {
          bodyFormData.append(element.name, element.value);
        });
        this.$store.dispatch('submitForm', {
          formID: this.page.formID,
          formData: bodyFormData
        }).then((valid) => {
          if (valid) {
            formElements.forEach(element => {
              if (element.classList.contains('wpcf7-text')) {
                element.value = '';
              }
            });
            this.submitting = false
            this.formFeedback = 'Thank you for your message.'
          }
        });
      } else {
        this.submitting = false
      }
    },
    onVerify(response) {
      if (response) this.recaptchaVerified = true;
    }
  }
}
</script>
