<template>
  <div class="calendar-links popup-container">
    <button @click="popup = !popup" class="btn">
      <FontAwesomeIcon :icon="icon.calendarAdd" />
      Add to Calendar
    </button>
    <ul v-if="popup" class="popup" @mouseenter="cancelTimeout" @mouseleave="timeoutClosePopup">
      <li v-for="(calendarLink, type) in links" :key="type">
        <a :href="fixEntities(calendarLink)" target="_blank" @click="popup = false">{{ calendars[type] }}</a>
      </li>
    </ul>
  </div>
</template>

<script>
import { faPlusSquare } from '@fortawesome/free-regular-svg-icons';

export default {
  props: {
    links: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      popup: false,
      icon: {
        calendarAdd: faPlusSquare
      },
      calendars:
      {
        'ical': 'iCal',
        'google': 'Google Calendar',
        'icalendar': 'Apple iCalendar',
        'office365': 'Office 365',
        'outlooklive': 'Outlook Live'
      }
    }
  },
  methods: {
    fixEntities(str) {
      return str.replace(/&#038;/g, '&')
    },
    timeoutClosePopup() {
      this.timeout = setTimeout(() => {
        this.popup = false
      }, 250)
    },
    cancelTimeout() {
      clearTimeout(this.timeout)
    }
  }
}
</script>
