import Vue from 'vue'
import Vuex from 'vuex'
import VueRouter from 'vue-router'
import Meta from 'vue-meta'
import InlineSvg from 'vue-inline-svg'
import * as VueGoogleMaps from 'vue2-google-maps'
import VueYouTubeEmbed from 'vue-youtube-embed'
import GmapMarker from 'vue2-google-maps/src/components/marker'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

import VueMoment from 'vue-moment';

// views
import App from './App'

// global components
Vue.component('inline-svg', InlineSvg);
Vue.component('FontAwesomeIcon', FontAwesomeIcon);
Vue.use(VueYouTubeEmbed)

// lodash utilities
// import _filter    from 'lodash/filter'
// import _sortBy    from 'lodash/sortBy'
// import _map       from 'lodash/map'
// import _debounce  from 'lodash/debounce'
// import _toNumber  from 'lodash/toNumber'
// import _each      from 'lodash/each'
// import _startCase from 'lodash/startCase'
// import _shuffle   from 'lodash/shuffle'
// import _random    from 'lodash/random'

Vue.use(Vuex)
import store from './store'
import routes from './routes'

// routes and router
const router = new VueRouter({
  routes,
  mode: 'history',
  scrollBehavior() {
    window.scrollTo(0, 0)
  }
})

router.beforeEach(async (to, from, next) => {
  if (to.fullPath === '/login' || to.fullPath === '/logout' || to.fullPath === '/forgot-password' || to.fullPath === '/reset-password' || to.fullPath === '/change-password') {
    next();
    return;
  }
  store.dispatch('checkAuth').then(() => {
    if (!store.getters.isLoggedIn) {
      next();
    } else {
      if (store.getters.getUser.meta.default_password_nag) {
        store.commit('setLoginMessage', 'You are using a temporary password. Please change your password now.');
        next('/change-password');
      } else {
        next();
      }
    }
  })
})

Vue.config.productionTip = false
Vue.use(VueRouter)
Vue.use(Meta)
// Vue.set(Vue.prototype, '_filter', _filter)
// Vue.set(Vue.prototype, '_sortBy', _sortBy)
// Vue.set(Vue.prototype, '_map', _map)
// Vue.set(Vue.prototype, '_debounce', _debounce)
// Vue.set(Vue.prototype, '_toNumber', _toNumber)
// Vue.set(Vue.prototype, '_each', _each)
// Vue.set(Vue.prototype, '_startCase', _startCase)
// Vue.set(Vue.prototype, '_shuffle', _shuffle)
// Vue.set(Vue.prototype, '_random', _random)
Vue.component('GmapMarker', GmapMarker)
Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyAnOevkk4RzJM_eeOdpe8l3dmh0zlJ_9KY',
    installComponents: true
  }
})
Vue.use(VueMoment)


new Vue({
  router,
  store,
  components: { App },
  template: '<App/>'
}).$mount('#app')
