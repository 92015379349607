<template>
  <div>
    <div v-if="error" class="error" v-html="error"></div>

    <div class="loading" v-if="loading">
      <div class="wrapper">
        <span class="spinner"></span>
        <p class="sr-only">Loading...</p>
      </div>
    </div>

    <div class="sponsors-page-list" v-else>
      <header class="sponsors-page-list--header">
        <div class="wrapper">
          <h1 v-html="page.title.rendered"></h1>
        </div>
      </header>

      <div class="wrapper">
        <div class="sponsors-page-list--content" v-if="page.content.rendered">
          <div v-html="page.content.rendered"></div>
        </div>

        <div class="sponsors-page-list--list">
          <div class="sponsors-page-list--item" v-for="(sponsor, index) in page.acf.sponsor" :key="`sponsor-${index}`">

            <div class="logo">
              <a :href="sponsor.website" target="_blank" v-if="sponsor.website">
                <img :src="sponsor.logo.sizes.medium" :alt="sponsor.logo.alt" />
              </a>
              <img v-else src="sponsor.logo.sizes.medium" :alt="sponsor.logo.alt" />
            </div>

            <div class="content">
              <h2>
                {{ sponsor.company }} <a :href="sponsor.website" target="_blank" v-if="sponsor.website">{{ cleanURL(sponsor.website) }}</a>
              </h2>
              <h3 v-if="sponsor.contact_name" v-html="sponsor.contact_name"></h3>
              <p v-if="sponsor.contact_email"><a :href="`mailto:${sponsor.contact_email}`">{{ sponsor.contact_email.toLowerCase() }}</a></p>
              <div v-if="sponsor.description" v-html="sponsor.description"></div>
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'SponsorsPage',
  data() {
    return {
      page: null,
      loading: true
    }
  },
  computed: {
    error() {
      return this.$store.getters.getError
    }
  },
  mounted() {
    this.$store.dispatch('getPage', 'sponsors').then(() => {
      this.loading = false
      this.page = this.$store.getters.getPage('sponsors')
    })
  },
  methods: {
    cleanURL(url) {
      url = url.replace(/(^\w+:|^)\/\//, '');
      url = url.replace(/^www\./, '');
      url = url.replace(/\/$/, '');
      return url;
    },
    autop(content) {
      content = content.replace(/(?:\r\n|\r|\n)/g, '<br />')
      content = '<p>' + content.replace(/<br \/><br \/>/g, '</p><p>') + '</p>'
      return content
    }
  },
  metaInfo() {
    return {
      title: 'Sponsors'
    }
  }
}

</script>
