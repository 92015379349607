var render = function render(){var _vm=this,_c=_vm._self._c;return _c('GmapMap',{attrs:{"center":_vm.position,"zoom":13,"options":{
    draggable: true,
    zoomControl: true,
    mapTypeControl: false,
    scaleControl: false,
    streetViewControl: false,
    rotateControl: false,
    fullscreenControl: false,
    disableDefaultUi: false,
    styles: _vm.mapStyle
  }}},[_c('GmapMarker',{attrs:{"icon":{
      url: require('@/assets/map-marker.png'),
      scaledSize: _vm.google && new _vm.google.maps.Size(35, 45)
    },"position":_vm.position,"clickable":true,"draggable":false},on:{"click":_vm.openDirections}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }