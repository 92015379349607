<template>
  <div>

    <div v-if="error" class="error" v-html="error"></div>

    <div v-if="event">

      <div class="lead-in">
        <div class="wrapper">
          <div class="lead-in--image">
            <img v-if="event.image" :src="event.image.sizes.large" />
          </div>
          <div class="lead-in--content">
            <h1>{{ event.title }}</h1>

            <!-- Date -->
            <dl v-if="event.event_start">
              <dt>
                <FontAwesomeIcon :icon="icon.calendar" />
                Event Date</dt>
              <dd>
                <span class="from">{{ event.event_start | moment(dateFormat) }}</span>
                <template v-if="event.event_end !== event.event_start"> - <span class="to">{{ event.event_end | moment(dateFormat) }}</span></template>
              </dd>
            </dl>

            <!-- Time -->
            <dl>
              <dt>
                <FontAwesomeIcon :icon="icon.clock" />
                Event Time</dt>
              <dd v-if="event.event_start_time">
                <span class="from">{{ event.event_start + ' ' + event.event_start_time | moment(hoursFormat) }}</span>
                <span class="to"> - {{ event.event_end + ' ' + event.event_end_time | moment(hoursFormat) }}</span>
              </dd>
              <dd v-else>
                All Day
              </dd>
            </dl>

            <!-- Venue -->
            <dl v-if="event.location">
              <dt>
                <FontAwesomeIcon :icon="icon.mapMarker" />
                {{ event.location.name }}</dt>
              <dd>
                <address v-html="event.location.address"
                ></address>
                <p><a :href="eventDirectionsURL" target="_blank">Get Directions</a></p>
              </dd>
            </dl>

            <template v-if="event.booked">
              <p>You are booked.</p>

              <CalendarLinks :links="event.cal" v-if="event.cal" />
            </template>
            <p v-else-if="!dateWithin(event.ticket.available_from, event.ticket.available_to)">
              Booking opens on {{ event.ticket.available_from | moment(dateFormat) }} and closes on {{ event.ticket.available_to | moment(dateFormat) }}.
            </p>
            <p v-else-if="event.ticket.available_spaces === 0">
              Booking is full
            </p>

          </div>
        </div>
      </div>

      <div class="body">
        <div class="wrapper">
          <div class="body--content">
            <template v-if="event.description">
              <h2>About the Event</h2>
              <div v-html="autop(event.description)"></div>
            </template>

            <template v-if="event.speaker">
              <h3>About the Speaker</h3>
              <div v-html="autop(event.speaker)"></div>
            </template>

            <div v-if="event.ticket && event.attendees.length > 0" class="members-list">
              <h3>Confirmed Attending <span>({{ attendeeSpaces }} / {{ event.ticket.spaces }})</span></h3>

              <VueSlickCarousel v-bind="membersCarouselSettings" class="members-list--row">
                <div class="member-thumbnail" :class="`role-${attendee.member.role}`" v-for="attendee in event.attendees" :key="attendee.id">
                  <div class="inner">
                    <img v-if="attendee.member.acf_json && attendee.member.acf_json.member_image" :src="attendee.member.acf_json.member_image.sizes.thumbnail" />
                    <div class="image-placeholder" v-else>
                      <span><FontAwesomeIcon :icon="icon.user" class="user-icon" /></span>
                    </div>
                    <h2 v-html="twoLines(attendee.member.name)"></h2>
                    <span class="guest-count" :title="`+${attendee.guests} Guest`" v-if="attendee.guests > 0">
                      +{{ attendee.guests }}
                    </span>
                    <abbr title="Sponsor" class="sponsor" v-if="attendee.member.role == 'sponsor'">
                      S
                    </abbr>
                  </div>
                </div>
              </VueSlickCarousel>
            </div>
          </div>
          <div class="body--map">
            <InlineMap :location="event.location" v-if="event.location" :directions="eventDirectionsURL" />
          </div>

          <div class="body--form booking" id="booking">

            <!-- No registration enabled for event -->
            <template v-if="!event.ticket">
              <h2>No Booking Available</h2>
              <p>RSVP is not required for this event.</p>
            </template>

            <!-- User has already booked -->
            <template v-else-if="event.booked">
              <h2>Your Booking</h2>

              <p v-if="bookingFormMessage">{{ bookingFormMessage }}

                <button @click="cancelBooking" class="txtbtn">Cancel Booking</button>
              </p>

              <p v-else-if="event.ticket.guests > event.booked.guests">
                You have booked {{ event.booked.guests }} of {{ event.ticket.guests }} guest spaces.

                <button @click="cancelBooking" class="txtbtn">Cancel Booking</button>

                <button @click="updateBooking" class="txtbtn">Update Booking</button>
              </p>

              <p v-else>You have already booked this event.

                <button @click="cancelBooking" class="txtbtn">Cancel Booking</button>
              </p>


              <!-- Calendar Links -->
              <CalendarLinks :links="event.cal" v-if="event.cal" />
            </template>

            <!-- Booking not yet open, or has closed -->
            <template v-else-if="!dateWithin(event.ticket.available_from, event.ticket.available_to)">
              <h2>Booking Closed</h2>
              <p>Booking opens on {{ event.ticket.available_from | moment(dateFormat) }} and closes on {{ event.ticket.available_to | moment(dateFormat) }}.</p>
            </template>

            <!-- No spaces remaining -->
            <template v-else-if="event.ticket.available_spaces === 0">
              <h2>Booking is Full</h2>
              <p>Sorry, this event is fully booked.</p>
            </template>

            <!-- User has not booked -->
            <div v-else-if="user.roles.includes('member') || user.roles.includes('sponsor')" class="form" :class="{'loading': submitting}">
              <span class="spinner" v-if="submitting"></span>

              <h2>Book Now</h2>
              <p>Spaces are limited. Book now.</p>

              <template v-if="numberOfGuests > 0">
                <fieldset class="columns">
                  <FormGroup label="Number of Guests" type="select" v-model="guestCount" :errors="errors.number_of_guests" @errors="updateErrors" :options="guestCountOptions" :showEmpty="false" @input="updateGuests" />
                </fieldset>

                <fieldset class="repeater" v-for="(guest, index) in guests" v-bind:key="'guestInfo'+index">
                  <fieldset class="columns">
                    <FormGroup label="Guest Name" :fieldName="'guestName'+index" v-model="guest.name" :errors="errors['guestName'+index]" @errors="updateErrors" />
                    <FormGroup label="Guest Email" :fieldName="'guestEmail'+index" v-model="guest.email" :errors="errors['guestEmail'+index]" @errors="updateErrors" />
                  </fieldset>
                </fieldset>
              </template>

              <div class="form-footer">
                <p>You are signed in as <strong>{{ user.name }}</strong> (<router-link to="/logout">Log out</router-link>)</p>

                <button @click="bookEvent" class="btn pri">Book Now</button>
              </div>
            </div>
            <div v-else-if="user.roles.includes('administrator')">
              <h2>Booking Unavailable</h2>
              <p>Admins cannot use this booking form.

                <a class="txtbtn" :href="`/wp-admin/edit.php?post_type=event&page=events-manager-bookings&event_id=${event.event_id}`">Manage Bookings</a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FormGroup from '@/components/FormGroup.vue';
import InlineMap from '@/components/InlineMap.vue';
import CalendarLinks from '@/components/CalendarLinks.vue';
import { faLocationDot, faUser } from '@fortawesome/free-solid-svg-icons';
import { faCalendarDays, faClock } from '@fortawesome/free-regular-svg-icons';
import VueSlickCarousel from 'vue-slick-carousel'

export default {
  name: 'EventPage',
  components: {
    FormGroup,
    InlineMap,
    CalendarLinks,
    VueSlickCarousel
  },
  mounted() {
    this.$store.dispatch('getEvent', this.$route.params.slug).then(() => {
      this.event = this.$store.getters.getEvent(this.$route.params.slug)

      if (!this.event) {
        this.$router.push('/events')
      }
    })
  },
  computed: {
    error() {
      return this.$store.getters.getError
    },
    user() {
      return this.$store.getters.getUser
    },
    numberOfGuests() {
      return this.event.ticket ? Math.min(this.event.ticket.guests, this.event.ticket.available_spaces) : 0
    },
    guestCountOptions() {
      return Array.from({ length: this.numberOfGuests + 1 }, (v, k) => k).map((i) => {
        return {
          value: i,
          name: i
        }
      })
    },
    eventDirectionsURL() {
      const location = this.event.location
      // If the location has a place_id, use it to generate a Google Maps URL
      if (location.place_id)
        return `https://www.google.com/maps/dir/?api=1&destination=${encodeURIComponent(location.name.replaceAll(' ', '+'))}+${encodeURIComponent(location.address.replaceAll('\n', '+').replaceAll(' ', '+'))}&destination_place_id=${location.place_id}`
      // Otherwise just use the latitude and longitude to generate a Google Maps URL
      else
        return `https://www.google.com/maps/dir/?api=1&destination=${location.latitude},${location.longitude}`
    },
    attendeeSpaces() {
      return this.event.attendees.reduce((total, attendee) => total + attendee.guests + 1, 0)
    }
  },
  data() {
    return {
      event: false,
      dateFormat: 'MMMM D, YYYY',
      hoursFormat: 'h:mm a',
      icon: {
        calendar: faCalendarDays,
        clock: faClock,
        mapMarker: faLocationDot,
        user: faUser
      },
      guestCount: 0,
      submitting: false,
      bookingFormMessage: '',
      guests: [],
      errors: {},
      membersCarouselSettings: {
        arrows: true,
        dots: false,
        infinite: false,
        speed: 300,
        slidesToShow: 10,
        slidesToScroll: 9,
        responsive: [
          {
            breakpoint: 1320,
            settings: {
              slidesToShow: 8,
              slidesToScroll: 7
            }
          },
          {
            breakpoint: 992,
            settings: {
              slidesToShow: 6,
              slidesToScroll: 5
            }
          },
          {
            breakpoint: 768,
            settings: {
              slidesToShow: 6,
              slidesToScroll: 5
            }
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 6,
              slidesToScroll: 5
            }
          }
        ]
      }
    }
  },
  methods: {
    dateWithin(from, to) {
      const now = new Date()
      if (!from && !to) {
        return false
      } else if (!to) {
        return now >= new Date(from)
      } else if (!from) {
        return now <= new Date(to)
      } else {
        return now >= new Date(from) && now <= new Date(to)
      }
    },
    updateBooking() {
      this.submitting = true
      this.$store.dispatch('cancelBooking', this.event.booked).then((success) => {
        this.submitting = false

        if (success) {
          this.guests = this.event.booked.attendees.slice(1);
          this.guestCount = this.guests.length
          this.event.booked = false
          this.event.attendees = this.event.attendees.filter((attendee) => attendee.id !== this.event.booked.id)
          this.$forceUpdate()
        } else {
          window.scrollTo(0, 0)
          this.$store.commit('setError', 'There was a problem cancelling your booking. Please try again later.')
        }
      })
    },
    cancelBooking() {
      this.submitting = true
      this.$store.dispatch('cancelBooking', this.event.booked).then((success) => {
        this.submitting = false

        if (success) {
          this.event.attendees = this.event.attendees.filter((attendee) => attendee.id !== this.event.booked.id)
          this.event.booked = false
          this.$forceUpdate()
        } else {
          window.scrollTo(0, 0)
          this.$store.commit('setError', 'There was a problem cancelling your booking. Please try again later.')
        }
      })
    },
    bookEvent() {
      this.submitting = true
      this.errors = {}

      if (!this.user.roles.includes('member') && !this.user.roles.includes('sponsor')) {
        window.scrollTo(0, 0)
        this.$store.commit('setError', 'Booking is open to Members only.')
        return;
      }

      // if (!this.bookingAgreetoTerms) {
      //   this.errors.bookingAgreetoTerms = 'You must agree to the terms and conditions to book a ticket.'
      // }

      this.guests.forEach((guest, index) => {
        if (!guest.name) {
          this.errors['guestName' + index] = 'Please enter a name for this guest.'
        }
        if (!guest.email) {
          this.errors['guestEmail' + index] = 'Please enter an email address for this guest.'
        }
      })

      if (Object.keys(this.errors).length > 0) {
        this.submitting = false
        this.$forceUpdate()
        return
      }

      this.$store.dispatch('bookEvent', {
        event: this.event,
        guests: this.guests
      }).then((booking) => {
        this.submitting = false

        if (booking) {
          this.event.booked = booking
          this.event.attendees.push(booking)
          this.bookingFormMessage = 'Your booking has been confirmed.'
          this.$forceUpdate()
        } else {
          window.scrollTo(0, 0)
          this.$store.commit('setError', 'There was a problem booking your space. Please try again later.')
        }
      }).catch(() => {
        this.submitting = false
        window.scrollTo(0, 0)
      })
    },
    updateErrors(errors) {
      if (errors[1] === false) {
        delete this.errors[errors[0]]
      } else {
        this.errors[errors[0]] = errors[1]
      }
    },
    updateGuests(num) {
      this.guests = Array.from({ length: num }, (v, k) => k).map((i) => {
        if (this.guests[i]) {
          return this.guests[i]
        } else {
          return {
            name: '',
            email: ''
          }
        }
      })
    },
    twoLines(name) {
      // Split the name at the middle, or at the last space before the middle
      const middleIndex = Math.floor(name.length / 2);
      const spaceIndex = name.lastIndexOf(' ', middleIndex);
      if (spaceIndex !== -1) {
        return name.substring(0, spaceIndex) + '<br />' + name.substring(spaceIndex + 1);
      } else {
        return name.replace(' ', '<br />');
      }
    },
    autop(content) {
      content = content.replace(/(?:\r\n|\r|\n)/g, '<br />')
      content = '<p>' + content.replace(/<br \/><br \/>/g, '</p><p>') + '</p>'
      return content
    }
  },
  metaInfo() {
    return {
      title: this.event ? this.event.title : 'Event'
    }
  }
}
</script>
