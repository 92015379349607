<template>
  <div>
    <div v-if="error" class="error" v-html="error"></div>

    <div class="loading" v-if="loading">
      <div class="wrapper">
        <span class="spinner"></span>
        <p class="sr-only">Loading...</p>
      </div>
    </div>

    <div v-else-if="page" class="wrapper">
      <h1 v-html="page.title.rendered"></h1>
      <div v-html="page.content.rendered"></div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'PrivacyPolicy',
  data() {
    return {
      page: null
    }
  },
  computed: {
    error() {
      return this.$store.getters.getError
    }
  },
  mounted() {
    this.$store.dispatch('getPage', 'privacy-policy').then(() => {
      this.page = this.$store.getters.getPage('privacy-policy')
    })
  },
  metaInfo() {
    return {
      title: 'Privacy Policy'
    }
  }
}

</script>
